import { Container } from '@chakra-ui/layout';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/alert';
import {
  doc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  getDoc,
} from '@firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { firestore } from '../api/firebase';
import FormRenderer from '../components/renderer';
import useAuth from '../hooks/useAuth';
import useFormManager from '../hooks/useFormManager';
import Page from '../layouts/Page';
import { canFillForms } from '../utils/permission';

const FormFill = () => {
  const { invitationId } = useParams();
  const navigate = useNavigate();
  const [invitation, setInvitation] = useState(null);
  const [schema, setSchema] = useState(null);
  const [formData] = useState(null);
  const { user } = useAuth();
  const { getFormSchema, saveFormData, saving } = useFormManager();

  const canFill = canFillForms(user);

  useEffect(() => {
    if (!invitationId) return;

    const unsubscribe = onSnapshot(
      doc(firestore, 'invitations', invitationId),
      (doc) => {
        setInvitation({ id: doc.id, ...doc.data() });
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [invitationId]);

  const loadFormSchema = useCallback(async () => {
    try {
      if (!invitation?.formId) return;
      const formSchema = await getFormSchema(invitation.formId);
      setSchema(formSchema);
    } catch (error) {
      console.log(error);
    }
  }, [invitation?.formId, getFormSchema]);

  const handleOnSubmit = useCallback(
    async (data) => {
      if (!canFill || !schema.id) return;

      try {
        // Get invitation ref from firestore
        const invitationRef = doc(firestore, `invitations/${invitationId}`);

        // Get Invitation document
        const invitationSnap = await getDoc(invitationRef);

        if (invitationSnap.exists()) {
          const dataToSave = {
            data,
            fieldTypes: schema?.fieldTypes,
            integration: schema?.integration ?? {},
            invitationData: { id: invitationSnap.id, ...invitationSnap.data() },
          };

          const recipientViewURL = await saveFormData(dataToSave, schema.id);

          let state = {};

          if (recipientViewURL?.length) {
            // Set navigation state to URL received, the URL is used to load DocuSign Viewer iframe.
            state = {
              viewURL: recipientViewURL,
            };
          }

          // Mark invitation as filled.
          await updateDoc(invitationRef, {
            completed: true,
            completedAt: serverTimestamp(),
            completedBy: {
              uid: user.uid,
              email: user.email,
            },
          });

          navigate(`/form-submitted`, { state });
        }
      } catch (error) {
        // TODO: show error
        console.log(error);
      }
    },
    [canFill, invitationId, schema, user, navigate, saveFormData]
  );

  useEffect(() => {
    loadFormSchema();
  }, [invitation, loadFormSchema]);

  if (!schema)
    return (
      <Page>
        <Container>
          <h1>Invalid Form</h1>
        </Container>
      </Page>
    );

  if (invitation?.completed) {
    return (
      <Page>
        <Container centerContent>
          <h1>Form has been submitted</h1>
        </Container>
      </Page>
    );
  }

  return (
    <Page>
      {saving ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Processing!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Your submission is in progress. Please wait...
          </AlertDescription>
        </Alert>
      ) : (
        <Container minW="3xl" minH="100vh">
          <FormRenderer
            schema={schema}
            onSubmit={handleOnSubmit}
            data={formData}
          />
        </Container>
      )}
    </Page>
  );
};

export default FormFill;
