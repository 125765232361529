/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  Progress,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { Form, Field, useField, useForm } from 'react-final-form';
import queryString from 'query-string';
import validate from './validate';

const onSubmit = async (values) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      window.location.href.replace(/\?.*/g, '') +
        '?' +
        queryString.stringify(values)
    );
  }
};

const HealthCheckForm = () => {
  let initialValues = queryString.parse(
    window.location.search.replace(/\?.*\/\?/g, '?') // strip out duplicate query string if present e.g. /health-check?orgId=yTV9GnGEbhBUj61GRlHG/?familySymptoms=no -> /health-check?familySymptoms=no
  );

  // initialValues.symptoms needs to be an array even if only 1 symptom is in the params
  if (typeof initialValues.symptoms === 'string') {
    initialValues.symptoms = [initialValues.symptoms];
  }

  const canSubmit =
    !initialValues.symptoms &&
    !initialValues.familySymptoms &&
    !initialValues.notes;

  return (
    <>
      <Box width={['100%', '50%']} m="0px auto">
        <Heading as="h1" size="xl" p={5} textAlign="center">
          Health Check Form
        </Heading>

        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            form,
            errors,
            submitting,
            pristine,
            values,
          }) => (
            <Box
              as="form"
              p={4}
              bg="white"
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              onSubmit={handleSubmit}
            >
              <Control name="symptoms" my={4}>
                <FormLabel htmlFor="symptoms">
                  Has your child had any of the following symptoms in the last
                  48 hours
                </FormLabel>
                <Stack pl={6} mt={1} spacing={1}>
                  <CheckboxArrayControl name="symptoms" value="fever or chills">
                    fever or chills
                  </CheckboxArrayControl>
                  <CheckboxArrayControl name="symptoms" value="cough">
                    cough
                  </CheckboxArrayControl>
                  <CheckboxArrayControl
                    name="symptoms"
                    value="shortness of breath or difficulty breathing"
                  >
                    shortness of breath or difficulty breathing
                  </CheckboxArrayControl>
                  <CheckboxArrayControl name="symptoms" value="fatigue">
                    fatigue
                  </CheckboxArrayControl>
                  <CheckboxArrayControl
                    name="symptoms"
                    value="muscle or body aches"
                  >
                    muscle or body aches
                  </CheckboxArrayControl>
                  <CheckboxArrayControl name="symptoms" value="headache">
                    headache
                  </CheckboxArrayControl>
                  <CheckboxArrayControl
                    name="symptoms"
                    value="new loss of taste or smell"
                  >
                    new loss of taste or smell
                  </CheckboxArrayControl>
                  <CheckboxArrayControl name="symptoms" value="sore throat">
                    sore throat
                  </CheckboxArrayControl>
                  <CheckboxArrayControl
                    name="symptoms"
                    value="congestion or runny nose"
                  >
                    congestion or runny nose
                  </CheckboxArrayControl>
                  <CheckboxArrayControl
                    name="symptoms"
                    value="nausea or vomiting"
                  >
                    nausea or vomiting
                  </CheckboxArrayControl>
                  <CheckboxArrayControl name="symptoms" value="diarrhea">
                    diarrhea
                  </CheckboxArrayControl>
                </Stack>
                <Error name="symptoms" />
              </Control>

              <Field
                name="familySymptoms"
                component={AdaptedRadioGroup}
                label="In the past 14 days, has your child or anyone in your household been in close contact with someone diagnosed with COVID-19 or experiencing symptoms consistent with COVID-19?"
              >
                <Radio value="yes" color="red">
                  Yes
                </Radio>{' '}
                <Radio value="no" color="green">
                  No
                </Radio>
              </Field>

              <TextareaControl
                name="notes"
                label="Anything else we should know about your child today?"
              />

              {canSubmit && (
                <>
                  <PercentComplete size="sm" my={5} hasStripe isAnimated />
                  <ButtonGroup spacing={4}>
                    <Button
                      bg={'blue.600'}
                      color={'white'}
                      isLoading={submitting}
                      loadingText="Submitting"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outline"
                      onClick={form.reset}
                      isDisabled={submitting || pristine}
                    >
                      Reset
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </Box>
          )}
        />
      </Box>
    </>
  );
};

export default HealthCheckForm;

const AdaptedTextarea = ({ input, meta, ...rest }) => (
  <Textarea {...input} {...rest} isInvalid={meta.error && meta.touched} />
);

const CheckboxArrayControl = ({ name, value, children }) => {
  const {
    input: { checked, ...input },
    meta: { error, touched },
  } = useField(name, {
    type: 'checkbox', // important for RFF to manage the checked prop
    value, // important for RFF to manage list of strings
  });
  return (
    <Checkbox {...input} isChecked={checked} isInvalid={error && touched}>
      {children}
    </Checkbox>
  );
};

const AdaptedRadioGroup = ({ input, meta, label, children }) => (
  <FormControl isInvalid={meta.touched && meta.invalid} my={4}>
    <FormLabel htmlFor={input.name}>{label}</FormLabel>
    <RadioGroup {...input}>{children}</RadioGroup>
    <FormErrorMessage>{meta.error}</FormErrorMessage>
  </FormControl>
);

const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { touched: true, error: true } });
  return <FormControl {...rest} isInvalid={error && touched} />;
};

const Error = ({ name }) => {
  const {
    meta: { error },
  } = useField(name, { subscription: { error: true } });
  return <FormErrorMessage>{error}</FormErrorMessage>;
};

const TextareaControl = ({ name, label }) => (
  <Control name={name} my={4}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <Field
      name={name}
      component={AdaptedTextarea}
      placeholder={label}
      id={name}
    />
    <Error name={name} />
  </Control>
);

const PercentComplete = (props) => {
  const form = useForm();
  const numFields = form.getRegisteredFields().length;
  const numErrors = Object.keys(form.getState().errors).length;
  return (
    <Progress
      value={numFields === 0 ? 0 : ((numFields - numErrors) / numFields) * 100}
      {...props}
    />
  );
};
