import { Box } from '@chakra-ui/layout';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => (
  <Box
    display="flex"
    cursor="grab"
    color="gray.400"
    fontWeight="extrabold"
    justifyContent="center"
  >
    :::
  </Box>
));

export default DragHandle;
