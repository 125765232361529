import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Text } from '@chakra-ui/layout';
import { Heading } from '@chakra-ui/react';
import React from 'react';

const Page = ({ children, title = '', subtitle = '' }) => {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.800')} py={8} px={8}>
      <Heading as="h1" size="lg">
        {title}
      </Heading>
      <Text
        fontSize={'sm'}
        color={useColorModeValue('gray.400', 'gray.400')}
        mb={4}
      >
        {subtitle}
      </Text>

      {children}
    </Box>
  );
};

export default Page;
