/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext } from 'react';
import { BuilderContext } from './BuilderProvider';

const useBuilder = () => {
  const { state, dispatch } = useContext(BuilderContext);

  const addPage = useCallback(() => {
    dispatch({
      type: 'ADD_PAGE',
    });
  }, []);

  const removePage = useCallback((id) => {
    dispatch({
      type: 'REMOVE_PAGE',
      payload: id,
    });
  }, []);

  const addQuestion = useCallback((page, payload) => {
    dispatch({
      type: 'ADD_QUESTION',
      page,
      payload,
    });
  }, []);

  const updateQuestion = useCallback((page, index, payload) => {
    dispatch({
      type: 'UPDATE_QUESTION',
      page,
      index,
      payload,
    });
  }, []);

  const removeQuestion = useCallback((page, name) => {
    dispatch({
      type: 'REMOVE_QUESTION',
      page,
      payload: name,
    });
  }, []);

  const orderQuestion = useCallback((page, oldIndex, newIndex) => {
    dispatch({
      type: 'MOVE_QUESTION',
      page,
      payload: { oldIndex, newIndex },
    });
  }, []);

  const insertQuestionBefore = useCallback((page, index, payload) => {
    dispatch({
      type: 'INSERT_QUESTION_BEFORE',
      page,
      index,
      payload,
    });
  }, []);

  const insertQuestionAfter = useCallback((page, index, payload) => {
    dispatch({
      type: 'INSERT_QUESTION_AFTER',
      page,
      index,
      payload,
    });
  }, []);

  const setProperties = useCallback((page, payload) => {
    dispatch({
      type: 'SET_PAGE_PROPERTY',
      page,
      payload,
    });
  }, []);

  const setTitle = useCallback((title) => {
    dispatch({
      type: 'SET_TITLE',
      payload: title,
    });
  }, []);

  const setTags = useCallback((tags) => {
    dispatch({
      type: 'SET_TAGS',
      payload: tags,
    });
  }, []);

  return {
    ...state,
    setTitle,
    setTags,
    addPage,
    removePage,
    addQuestion,
    updateQuestion,
    removeQuestion,
    orderQuestion,
    insertQuestionAfter,
    insertQuestionBefore,
    setProperties,
  };
};

export default useBuilder;
