import { Box } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import useBuilder from '../useBuilder';

const PageMenu = ({ index, page }) => {
  const { removePage } = useBuilder();

  // Don't show menu if it's the first page.
  if (!index) return null;

  return (
    <Box display="flex" justifyContent="flex-start" alignSelf="center" pl="4">
      <Menu>
        <MenuButton variant="ghost" aria-label="Options">
          <FaEllipsisV />
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<FaTimes />}
            onClick={() => removePage(page.id)}
            disabled
          >
            Delete Page
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default PageMenu;
