import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input';
import React from 'react';

const FieldName = ({ cardState, setCardState }) => {
  return (
    <InputGroup size="xs" width="30%">
      <InputLeftAddon children="name" />

      <Input
        placeholder="Field name"
        value={cardState?.name}
        onFocus={(e) => e.target.select()}
        onChange={(e) =>
          setCardState((prev) => ({ ...prev, name: e.target.value }))
        }
      />
    </InputGroup>
  );
};

export default React.memo(FieldName);
