import { camelCase, flatten, map } from 'lodash';
import { customAlphabet } from 'nanoid';

// Generate a unique id
export function generateFieldName(name, state = []) {
  // If name is not provided, set default value.
  if (!name?.length) name = 'fieldName';

  // Camel case and shorten name to max length of 17 characters.
  name = camelCase(name).substring(0, 17);

  // Check if name is unique.
  const exists = flatten(map(state, 'questions')).some(
    (question) => question.name === name
  );

  // If name is not unique, add a random number to the end.
  if (exists) {
    const nanoid = customAlphabet(
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      10
    );
    return `${name}-${nanoid()}`;
  }

  return name;
}

// Insert question before array of objects
export function insertQuestionBefore(questions = [], index, question) {
  if (typeof index !== 'number' || index < 0) return [question, ...questions];

  return [...questions.slice(0, index), question, ...questions.slice(index)];
}

// Insert question after array of objects
export function insertQuestionAfter(questions = [], index, question) {
  if (typeof index !== 'number' || index < 0) return [...questions, question];

  return [
    ...questions.slice(0, index + 1),
    question,
    ...questions.slice(index + 1),
  ];
}
