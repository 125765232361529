import { Editable, EditableInput } from '@chakra-ui/editable';
import {
  HStack,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import EditableControls from '../EditableControls';
import useBuilder from './useBuilder';

const FormTags = () => {
  const [tagValue, setTagValue] = useState('');
  const { tags = [], setTags } = useBuilder();

  // Add item if not in array.
  const addItem = useCallback(
    (item = '') => {
      if (!item?.length) return;
      if (tags.indexOf(item) === -1) {
        const updatedTags = [...tags, item.trim().toLocaleLowerCase()];
        setTags(updatedTags);
        setTagValue('');
      }
    },
    [setTags, tags]
  );

  // Remove item from array.
  const removeItem = useCallback(
    (item) => {
      const index = tags.indexOf(item);
      if (index === -1) {
        return;
      }
      const updatedTags = [...tags.slice(0, index), ...tags.slice(index + 1)];
      setTags(updatedTags);
    },
    [setTags, tags]
  );

  return (
    <Stack alignItems="center" spacing="4" direction={['column', 'row']}>
      <Text fontWeight="bold">Tags:</Text>
      {tags.map((tag) => (
        <Tag key={tag} variant="subtle">
          <TagLabel>{tag}</TagLabel>
          <TagCloseButton onClick={() => removeItem(tag)} />
        </Tag>
      ))}

      <Editable value={tagValue} onSubmit={addItem}>
        <HStack>
          <EditableInput onChange={(e) => setTagValue(e.target.value)} />
          <EditableControls />
        </HStack>
      </Editable>
    </Stack>
  );
};

export default FormTags;
