import React from 'react';
import Page from '../layouts/Page';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { HStack, Text } from '@chakra-ui/layout';

const Settings = () => {
  return (
    <Page title="Settings" subtitle="Various settings">
      <HStack>
        <Text>Color Mode:</Text>
        <ColorModeSwitcher />
      </HStack>
    </Page>
  );
};

export default Settings;
