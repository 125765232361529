import { ButtonGroup, IconButton } from '@chakra-ui/button';
import { useEditableControls } from '@chakra-ui/editable';
import { Box, Flex } from '@chakra-ui/layout';
import React from 'react';
import { FaCheck, FaEdit, FaTimes } from 'react-icons/fa';

export default function EditableControls(props) {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return (
    <Box ml="2" {...props}>
      {isEditing ? (
        <ButtonGroup justifyContent="center" size="sm">
          <IconButton icon={<FaCheck />} {...getSubmitButtonProps()} />
          <IconButton icon={<FaTimes />} {...getCancelButtonProps()} />
        </ButtonGroup>
      ) : (
        <Flex justifyContent="flex-end">
          <IconButton size="sm" icon={<FaEdit />} {...getEditButtonProps()} />
        </Flex>
      )}
    </Box>
  );
}
