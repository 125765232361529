import { forwardRef } from 'react';
import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { FaCog, FaEdit } from 'react-icons/fa';
import { Tab, useTab } from '@chakra-ui/tabs';

const CustomTab = forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];

  return (
    <Button
      aria-label="Edit"
      size="sm"
      bgColor={!isSelected ? 'transparent' : ''}
      marginX="1"
      as={Tab}
      {...tabProps}
    >
      <Box as="span" marginX="1">
        {tabProps?.children === 'Edit' ? <FaEdit /> : <FaCog />}
      </Box>
      {tabProps.children}
    </Button>
  );
});
export default CustomTab;
