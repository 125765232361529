import { ChakraProvider } from '@chakra-ui/react';
import theme from '@chakra-ui/theme';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import SidebarWithHeader from './layouts/SideBarWithHeader';
import Auth from './pages/Auth';
import FormEditor from './pages/FormEditor';
import FormFill from './pages/FormFill';
import FormPreview from './pages/FormPreview';
import Forms from './pages/Forms';
import FormsList from './pages/FormsList';
import FormSubmittedSuccess from './pages/FormSubmittedSuccess';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import NotAuthorized from './pages/NotFoundAuthorized';
import Settings from './pages/Settings';
import HealthCheck from './pages/HealthCheck';
import { AuthProvider } from './providers/AuthProvider';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <SidebarWithHeader>
          <Routes>
            <Route index element={<Navigate to="/forms" />} />
            <Route
              path="/forms"
              element={
                <PrivateRoute>
                  <FormsList />
                </PrivateRoute>
              }
            />
            <Route path="/forms/*" element={<Forms />}>
              <Route path=":formId/editor" element={<FormEditor />} />
              <Route path=":formId" element={<FormPreview />} />
              <Route path="invitation/:invitationId" element={<FormFill />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/form-submitted"
              element={
                <PrivateRoute>
                  <FormSubmittedSuccess />
                </PrivateRoute>
              }
            />
            <Route path="/sign-in" element={<Login />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/401" element={<NotAuthorized />} />
            <Route path="/health-check" element={<HealthCheck />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SidebarWithHeader>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
