import {
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as signOutAuth,
  signInWithCustomToken,
} from 'firebase/auth';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { auth } from '../api/firebase';

export const AuthContext = createContext();

const initialState = {
  user: null,
  isAuthenticating: false,
  isAuthenticated: false,
  authError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        isAuthenticating: true,
        authError: null,
      };

    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        isAuthenticating: false,
        isAuthenticated: true,
        authError: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        authError: action.payload,
        isAuthenticating: false,
        isAuthenticated: false,
      };
    case 'SIGN_OUT':
      return initialState;
    default:
      return state;
  }
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await auth.currentUser.getIdTokenResult();

        const {
          parent = false,
          coordinator = false,
          organizationAdmin = false,
          moxitAdmin = false,
        } = idTokenResult?.claims;

        let claims = []
          .concat(parent ? ['parent'] : [])
          .concat(coordinator ? ['coordinator'] : [])
          .concat(organizationAdmin ? ['organizationAdmin'] : [])
          .concat(moxitAdmin ? ['moxitAdmin'] : []);

        dispatch({
          type: 'SET_USER',
          payload: {
            ...user,
            claims,
          },
        });
      }
    });
    return unsubscribe;
  }, []);

  const signUp = useCallback(async (email, password, displayName) => {
    try {
      const createdUser = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(auth.currentUser, { displayName });

      return createdUser;
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: { message: 'Unable to sign up.' },
      });
      throw new Error(error);
    }
  }, []);

  const signIn = useCallback(async (email, password) => {
    try {
      const signedInUser = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return signedInUser;
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: 'Unable to Sign in, invalid username and/or password.',
        },
      });
      throw new Error(error);
    }
  }, []);

  const signInWithToken = useCallback(async (token) => {
    try {
      const signedInUser = await signInWithCustomToken(auth, token);
      return signedInUser;
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error.message ?? 'Unable to Sign in, using custom token.',
        },
      });
      console.log(error.message);
      throw new Error(error);
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      await signOutAuth(auth);

      // redirect to sign out page
      window.location.href = `${process.env.REACT_APP_WS_AUTH_URL}/signout`;
      // dispatch({ type: 'SIGN_OUT' });
    } catch (error) {
      console.log(error);
      dispatch({ type: 'SET_ERROR', payload: error });
      throw new Error(error);
    }
  }, []);

  const startSignIn = useCallback(() => {
    dispatch({ type: 'SIGN_IN' });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        ...state,
        signUp,
        startSignIn,
        signIn,
        signInWithToken,
        signOut,
      }}
    >
      {/* FIXME: Add proper loader */}
      {state?.isAuthenticating ? <div>Authenticating...</div> : children}
    </AuthContext.Provider>
  );
}
