import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export function PrivateRoute({ children, redirectTo = '/sign-in' }) {
  const { auth } = useAuth();
  const location = useLocation();

  return auth?.currentUser ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ from: location.pathname }} />
  );
}

export function PrivateOutlet({ redirectTo = '/sign-in' }) {
  const { auth } = useAuth();
  const location = useLocation();
  return auth?.currentUser ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} state={{ from: location.pathname }} />
  );
}
