import { useToast } from '@chakra-ui/react';
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from '@firebase/firestore';
import { useCallback, useState } from 'react';
import { firestore } from '../api/firebase';
import useAuth from './useAuth';

const useFormManager = () => {
  const [saving, setSaving] = useState(false);
  const { auth, user } = useAuth();
  const toast = useToast();

  const getFormSchema = useCallback(async (id) => {
    try {
      const formRef = doc(firestore, `forms/${id}`);
      const docSnap = await getDoc(formRef);
      if (docSnap.exists()) return { id: docSnap.id, ...docSnap.data() };
    } catch (error) {
      console.log(error);
      throw new Error(error.message || 'Error fetching form schema');
    }
  }, []);

  const saveFormData = useCallback(
    async (data, formId) => {
      setSaving(true);
      if (!auth?.currentUser.uid || !formId || !user?.accessToken) return;

      try {
        const fetchResponse = await fetch(
          `${process.env.REACT_APP_WS_FORMS_API_URL}/${formId}/ds-save-response`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.accessToken}`,
            },
            body: JSON.stringify(data),
          }
        );

        setSaving(false);

        if (fetchResponse.status !== 200) {
          throw new Error(fetchResponse.statusText);
        }

        const { recipientViewURL = null } = await fetchResponse.json();

        return recipientViewURL;
      } catch (error) {
        setSaving(false);
        throw new Error(error.message || 'Error saving form data');
      }
    },
    [auth, user]
  );

  const duplicateForm = useCallback(
    async (id) => {
      if (!auth?.currentUser.uid) return;
      try {
        const docSnap = await getDoc(doc(firestore, `forms/${id}`));
        if (docSnap.exists()) {
          const newFormRef = doc(collection(firestore, 'forms'));
          const data = docSnap.data();

          data.id = newFormRef.id;
          data.title = `Copy of ${data.title}`;
          data.published = false;
          data.responseCount = 0;

          const now = serverTimestamp();
          const actionBy = {
            uid: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || auth.currentUser.email,
            email: auth.currentUser.email,
          };

          // Add audit fields.
          data.createdAt = now;
          data.updatedAt = now;
          data.createdBy = actionBy;
          data.updatedBy = actionBy;

          await setDoc(newFormRef, data);
          toast({
            position: 'top',
            title: 'Form duplicated',
            description: 'Form duplicated successfully',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          position: 'top',
          title: 'Error',
          description: error?.message ?? 'Error duplicating form.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        console.log(error);
        throw new Error(error.message || 'Error duplicating form');
      }
    },
    [auth?.currentUser, toast]
  );

  return { getFormSchema, saveFormData, duplicateForm, saving };
};

export default useFormManager;
