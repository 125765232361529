import { Checkbox } from '@chakra-ui/checkbox';
import { Input } from '@chakra-ui/input';
import { Box, Spacer } from '@chakra-ui/layout';
import React from 'react';

const FieldOptions = ({ cardState, setCardState }) => {
  const isChecked = React.useMemo(() => {
    return !!cardState?.rules?.required;
  }, [cardState]);

  return (
    <Box mx="1">
      <Checkbox
        isChecked={isChecked}
        onChange={(e) => {
          setCardState((prev) => ({
            ...prev,
            rules: {
              ...prev.rules,
              required: e.target.checked ? 'This field is required' : false,
            },
          }));
        }}
      >
        Required
      </Checkbox>
      <Spacer my="2" />
      {isChecked && (
        <Input
          size="sm"
          value={cardState?.rules?.required || ''}
          onFocus={(e) => e.target.select()}
          onChange={(e) =>
            setCardState((prev) => ({
              ...prev,
              rules: {
                ...prev?.rules,
                required: e.target.value,
              },
            }))
          }
        />
      )}
    </Box>
  );
};

export default React.memo(FieldOptions);
