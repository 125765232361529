import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const FormSubmittedSuccess = () => {
  const { state } = useLocation();

  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Thank you for your submission!
      </Heading>

      {state?.viewURL && (
        <Box mt={6} mx="-10">
          <iframe
            id="mainframe"
            title="Signature View"
            src={state.viewURL}
            scrolling="yes"
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
            frameBorder="0"
          />
        </Box>
      )}
    </Box>
  );
};

export default FormSubmittedSuccess;
