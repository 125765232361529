import { Button, ButtonGroup } from '@chakra-ui/button';
import { Box, Stack, Text } from '@chakra-ui/layout';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FieldPicker from '../fields';

const FormRenderer = ({ data, schema = [], onSubmit }) => {
  const methods = useForm({
    defaultValues: data,
  });

  // Memoize the schema.
  const { id, pages = [] } = useMemo(() => schema, [schema]);

  const [currentPage, setCurrentPage] = useState(0);
  const [formData, setFormData] = useState({});

  const onSubmitPageData = (pageData) => {
    // Next page.
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
      setFormData((prev) => ({ ...prev, ...pageData }));
    } else {
      // Submit the form.
      const filledData = { ...formData, ...pageData };

      if (typeof onSubmit === 'function') {
        onSubmit(filledData);
      } else {
        console.log('Form submitted.', { data: filledData });
      }
    }
  };

  const isLastPage = currentPage === pages.length - 1;

  const hasQuestions = pages[currentPage]?.questions?.length ?? 0;

  return (
    <FormProvider {...methods}>
      <Stack direction="column" maxW="container.md">
        <Box mb="10">
          <Text fontSize="xl" fontWeight="bold">
            {pages[currentPage]?.title}
          </Text>
          <Text>{pages[currentPage]?.description}</Text>
        </Box>

        {pages[currentPage]?.questions?.map((field) => {
          return (
            <form
              id={`${id}-${currentPage}`}
              key={field.name}
              onSubmit={methods.handleSubmit(onSubmitPageData)}
              noValidate
            >
              <FieldPicker key={field.name} field={field} />
            </form>
          );
        })}
        {hasQuestions ? (
          <ButtonGroup spacing="6">
            {currentPage > 0 ? (
              <Button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              >
                Previous
              </Button>
            ) : null}
            <Button
              form={`${id}-${currentPage}`}
              isLoading={methods?.formState?.isSubmitting}
              colorScheme={isLastPage ? 'green' : 'teal'}
              type="submit"
            >
              {isLastPage ? 'Submit' : 'Next'}
            </Button>
          </ButtonGroup>
        ) : null}
      </Stack>
    </FormProvider>
  );
};

export default FormRenderer;
