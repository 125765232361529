import React from 'react';
import { PrivateOutlet } from '../components/PrivateRoute';

const Forms = () => {
  return (
    <>
      <PrivateOutlet />
    </>
  );
};

export default Forms;
