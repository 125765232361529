const formManagerRoles = ['moxitAdmin', 'organizationAdmin'];
const formFillerRoles = ['parent'];

export const canManageForms = (user) => {
  if (!user?.claims?.length) return false;
  const { claims = [] } = user;
  return formManagerRoles.some((role) => claims.includes(role));
};

export const canFillForms = (user) => {
  if (!user?.claims?.length) return false;
  const { claims = [] } = user;
  return formFillerRoles.some((role) => claims.includes(role));
};
