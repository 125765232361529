import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

const Phone = ({ name, label, placeholder, rules }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const fieldError = errors[name];

  return (
    <FormControl isInvalid={fieldError} isRequired={rules?.required} mb="5">
      <FormLabel htmlFor={name}>{label}</FormLabel>

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Input
            id={name}
            placeholder={placeholder}
            type="tel"
            as={InputMask}
            mask="(999)-999-9999"
            {...register(name, {
              ...rules,
              pattern: {
                value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                message: 'Invalid phone number',
              },
              // TODO: Format phone number to E.164 format e.g.(+15555555555)
              // setValueAs: (value) => formatPhoneNumber(value),
            })}
            {...field}
          />
        )}
      />

      <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Phone;
