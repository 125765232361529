import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import useAuth from '../hooks/useAuth';

const useQueryHashJWT = () => {
  const { hash, search } = useLocation();
  if (!hash) return null;

  // get redirect path using qs.parse
  const { ret } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const { jwt = null } = JSON.parse(
    Buffer.from(hash?.slice(1), 'base64')?.toString()
  );
  return { jwt, ret };
};

const Auth = () => {
  const { signInWithToken } = useAuth();
  const navigate = useNavigate();
  const { jwt, ret } = useQueryHashJWT();

  useEffect(() => {
    if (!jwt?.length) return;
    (async () => {
      try {
        await signInWithToken(jwt);
        navigate(ret ?? '/', {
          replace: true,
        });
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [jwt, navigate, ret, signInWithToken]);

  return <div>Authenticating</div>;
};

export default Auth;
