import React from 'react';

import HealthCheckForm from '../components/HealthCheckForm';

const HealthCheck = () => {
  return (
    <>
      <HealthCheckForm />
    </>
  );
};

export default HealthCheck;
