import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const LongAnswer = ({ name, label, placeholder, rules }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[name];

  return (
    <FormControl isInvalid={fieldError} isRequired={rules?.required} mb="5">
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea
        id={name}
        placeholder={placeholder}
        {...register(name, rules)}
      />
      <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default LongAnswer;
