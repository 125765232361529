import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import { useDayzed } from 'dayzed';
import moment from 'moment';
import React from 'react';

const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const weekdayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const BackButton = (props) => {
  const { calendars, getBackProps } = props;
  return (
    <HStack>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {'<<'}
      </Button>
      <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
        {'<'}
      </Button>
    </HStack>
  );
};

const ForwardButton = (props) => {
  const { calendars, getForwardProps } = props;
  return (
    <HStack>
      <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
        {'>'}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {'>>'}
      </Button>
    </HStack>
  );
};

function Calendar({ calendars, getBackProps, getForwardProps, getDateProps }) {
  if (!calendars?.length) return null;
  return (
    <HStack width="full">
      {calendars.map((calendar) => (
        <VStack key={`${calendar.month}${calendar.year}`} width="full">
          <HStack width="max">
            <BackButton calendars={calendars} getBackProps={getBackProps} />
            <Heading size="sm" textAlign="center">
              {monthNamesShort[calendar.month]} {calendar.year}
            </Heading>
            <ForwardButton
              calendars={calendars}
              getForwardProps={getForwardProps}
            />
          </HStack>
          <Divider />
          <SimpleGrid columns={7} spacing={2} textAlign="center">
            {weekdayNamesShort.map((day) => (
              <Box key={`${calendar.month}${calendar.year}${day}`}>
                <Text fontSize="sm" fontWeight="semibold">
                  {day}
                </Text>
              </Box>
            ))}
            {calendar.weeks.map((week, weekIndex) =>
              week.map((dateObj, index) => {
                const {
                  date,
                  today,
                  // prevMonth,
                  // nextMonth,
                  selected,
                } = dateObj;
                const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;

                if (!dateObj) {
                  return (
                    <div
                      key={key}
                      style={{
                        display: 'inline-block',
                        width: 'calc(100% / 7)',
                        border: 'none',
                        background: 'transparent',
                      }}
                    />
                  );
                }

                return (
                  <Button
                    key={key}
                    size="sm"
                    variant="outline"
                    borderColor={today ? 'purple.400' : 'transparent'}
                    bg={selected ? 'purple.200' : undefined}
                    {...getDateProps({ dateObj })}
                  >
                    {date.getDate()}
                  </Button>
                );
              })
            )}
          </SimpleGrid>
        </VStack>
      ))}
    </HStack>
  );
}

const DateInput = React.forwardRef(
  ({ value, onChange, format, ...props }, ref) => {
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const popoverRef = React.useRef(ref);
    const initialFocusRef = React.useRef(null);

    useOutsideClick({
      ref: popoverRef,
      handler: () => setPopoverOpen(false),
    });

    const onDateSelected = (selectedDate) => {
      if (!selectedDate?.date) return;
      onChange(moment(selectedDate.date, 'MM/DD/YYYY').format(format));
      setPopoverOpen(false);
    };

    const dayzedData = useDayzed({
      showOutsideDays: true,
      onDateSelected,
      selected: value,
    });

    return (
      <Popover
        placement="bottom-start"
        variant="responsive"
        isOpen={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        initialFocusRef={initialFocusRef}
        isLazy
      >
        <PopoverTrigger>
          <Input
            {...props}
            ref={initialFocusRef}
            autoComplete="off"
            value={value ? moment(value, format).format(format) : ''}
            onClick={() => setPopoverOpen(!popoverOpen)}
            onChange={(e) => e.target.value}
          />
        </PopoverTrigger>
        <PopoverContent ref={popoverRef}>
          <PopoverBody>
            <Calendar {...dayzedData} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

export default DateInput;
