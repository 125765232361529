import { Input } from '@chakra-ui/input';
import { Stack, Text } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import React from 'react';

const DocuSign = ({ integration, onChange }) => {
  return (
    <Stack direction="column" my="10">
      <Text fontSize="sm" fontWeight="bold">
        DocuSign:
      </Text>

      <label htmlFor="docuSign-templateId">Template ID</label>
      <Input
        id="docuSign-templateId"
        placeholder="Template ID"
        value={integration?.docuSign?.templateId || ''}
        onChange={(e) =>
          onChange({
            ...integration,
            docuSign: {
              ...integration.docuSign,
              templateId: e.target.value,
            },
          })
        }
      />

      <label htmlFor="recipient-role">Recipient Role</label>
      <Input
        id="recipient-role"
        placeholder="Recipient Role"
        value={integration?.docuSign?.recipientRole || ''}
        onChange={(e) =>
          onChange({
            ...integration,
            docuSign: {
              ...integration.docuSign,
              recipientRole: e.target.value,
            },
          })
        }
      />

      <label htmlFor="message-subject">Message Subject</label>
      <Input
        id="message-subject"
        placeholder="Message Subject"
        value={integration?.docuSign?.messageSubject || ''}
        onChange={(e) =>
          onChange({
            ...integration,
            docuSign: {
              ...integration.docuSign,
              messageSubject: e.target.value,
            },
          })
        }
      />

      <label htmlFor="message-blurb">Message Blurb</label>
      <Textarea
        id="message-blurb"
        placeholder="Message Blurb"
        value={integration?.docuSign?.messageBlurb || ''}
        onChange={(e) =>
          onChange({
            ...integration,
            docuSign: {
              ...integration.docuSign,
              messageBlurb: e.target.value,
            },
          })
        }
      />
    </Stack>
  );
};

export default DocuSign;
