import { ButtonGroup, IconButton } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input';
import { Stack } from '@chakra-ui/layout';
import { Radio } from '@chakra-ui/radio';
import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

const FieldSelectable = ({
  cardState: { name, type, options: fieldOptions },
  setCardState,
}) => {
  const [options, setOptions] = useState(
    fieldOptions || [
      {
        label: 'Option 1',
        value: 'Option 1',
        optionKey: `${name}-option-1`,
      },
    ]
  );

  useEffect(() => {
    setCardState((prev) => ({
      ...prev,
      options: options.map((o, i) => ({
        ...o,
        optionKey: `${name}-option-${i + 1}`, // Add unique key for each option
      })),
    }));
  }, [name, options, setCardState]);

  if (type === 'radio' || type === 'checkbox')
    return (
      <Stack spacing="10px" marginTop="4">
        {options.map((option, index) => (
          <InputGroup key={index} size="sm">
            <InputLeftAddon>
              {type === 'checkbox' ? (
                <Checkbox isChecked={false} />
              ) : (
                <Radio isChecked={false} />
              )}
              <Text ml={'2'} fontSize="sm">
                {option.optionKey}
              </Text>
            </InputLeftAddon>
            <Input
              placeholder="Enter option"
              value={option?.label}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const currentOptions = [...options];
                currentOptions[index] = {
                  ...currentOptions[index],
                  label: e.target.value,
                  value: e.target.value,
                };
                setOptions(currentOptions);
              }}
            />

            <ButtonGroup variant="ghost" marginLeft="2" alignItems="center">
              <IconButton
                aria-label="Add option"
                icon={<FaPlusCircle />}
                onClick={() => {
                  const currentOptions = [...options];
                  const key = currentOptions?.length
                    ? currentOptions?.length + 1
                    : 1;
                  currentOptions.splice(index + 1, 0, {
                    label: `Option ${key}`,
                    value: `Option ${key}`,
                    optionKey: `${name}-option-${key}`,
                  });
                  setOptions(currentOptions);
                }}
              />
              <IconButton
                aria-label="Remove option"
                icon={<FaMinusCircle />}
                onClick={() => {
                  if (options.length === 1) return;
                  let prevOptions = [...options];
                  prevOptions.splice(index, 1);

                  // Regenerate option keys.
                  prevOptions = prevOptions.map((o, i) => ({
                    ...o,
                    optionKey: `${name}-option-${i + 1}`,
                  }));

                  setOptions(prevOptions);
                }}
              />
            </ButtonGroup>
          </InputGroup>
        ))}
      </Stack>
    );

  return null;
};

export default FieldSelectable;
