import { Button } from '@chakra-ui/button';
import { HStack, Text } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { Switch } from '@chakra-ui/switch';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { doc, getDoc, serverTimestamp, setDoc } from '@firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { Navigate, useParams } from 'react-router-dom';
import { firestore } from '../api/firebase';
import FormBuilder from '../components/builder';
import DocuSign from '../components/builder/integration/docusign';
import useAuth from '../hooks/useAuth';
import Page from '../layouts/Page';
import { canManageForms } from '../utils/permission';

const FormEditor = () => {
  const { formId } = useParams();
  const { auth, user } = useAuth();
  const [schema, setSchema] = useState();
  const [published, setPublished] = useState();
  const [integration, setIntegration] = useState({
    docSign: {
      templateId: '',
    },
  });
  const toast = useToast();

  const getFormSchema = useCallback(async (id) => {
    try {
      const formRef = doc(firestore, `forms/${id}`);

      let formSchema = {
        id: formRef.id,
      };

      const docSnap = await getDoc(formRef);

      // Form exists flow is edit.
      if (docSnap.exists()) {
        formSchema = {
          ...formSchema,
          ...docSnap.data(),
        };
      }

      setPublished(formSchema?.published || false);
      setIntegration(formSchema?.integration || {});
      setSchema(formSchema);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const saveFormSchema = useCallback(
    async (formSchema) => {
      if (!auth?.currentUser.uid) return;
      try {
        // TODO: add validator.
        if (!formSchema?.title) throw new Error('Form title is required');

        const formRef = doc(firestore, `forms/${formSchema.id}`);
        const auditFields = {};
        const user = {
          uid: auth.currentUser.uid,
          email: auth.currentUser.email,
          displayName: auth.currentUser?.displayName ?? '',
        };

        // Add createdBy
        if (!formSchema.createdBy) {
          auditFields.createdBy = user;
        }

        // Add createdAt and updatedAt dates
        if (!formSchema.createdAt) {
          auditFields.createdAt = serverTimestamp();
        }

        auditFields.updatedAt = serverTimestamp();
        auditFields.updatedBy = user;

        // Collect all field types.
        const fieldTypes = {};
        formSchema.pages.forEach((page) => {
          page.questions.forEach((field) => {
            fieldTypes[field.name] = {
              type: field.type,
              label: field.label,
            };

            if (field?.options?.length) {
              fieldTypes[field.name].options = field?.options || [];
            }
          });
        });

        await setDoc(formRef, {
          ...formSchema,
          fieldTypes: fieldTypes,
          integration,
          published,
          ...auditFields,
        });
        toast({
          position: 'top',
          title: 'Form Saved',
          description: 'Changes to the form are saved successfully.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          position: 'top',
          title: 'Error',
          description: error?.message ?? 'Error saving form.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        console.log(error);
      }
    },
    [auth, published, integration, toast]
  );

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    getFormSchema(formId);
  }, [auth?.currentUser?.uid, formId, getFormSchema]);

  const builderRef = React.useRef();

  const canManage = canManageForms(user);

  if (!canManage) return <Navigate to="/401" />;

  return (
    <Page title="Form Editor" subtitle="Add new or edit existing forms.">
      <Tabs>
        <TabList>
          <Tab>Editor</Tab>
          <Tab>Integrations</Tab>

          <HStack align="center" justify="flex-end" ml="auto" mb="4">
            <HStack mr="10">
              <Text fontSize="sm" fontWeight="bold">
                Published
              </Text>
              <Switch
                isChecked={published}
                onChange={(e) => setPublished(e.target.checked)}
                title="Publish"
              />
            </HStack>
            <Button
              bg={'blue.400'}
              rounded={'full'}
              color={'white'}
              leftIcon={<FiSave />}
              _hover={{ bg: 'blue.500' }}
              _focus={{ bg: 'blue.500' }}
              onClick={() => {
                if (builderRef.current) builderRef.current.publishForm();
              }}
              mb={8}
            >
              Save
            </Button>
          </HStack>
        </TabList>

        <TabPanels>
          <TabPanel>
            <FormBuilder
              ref={builderRef}
              schema={schema}
              onPublish={(formSchema) => saveFormSchema(formSchema)}
            />
          </TabPanel>

          <TabPanel>
            <DocuSign onChange={setIntegration} integration={integration} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};

export default FormEditor;
