import { Box } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { FaArrowDown, FaArrowUp, FaEllipsisH } from 'react-icons/fa';
import useBuilder from '../useBuilder';

const BottomMenu = ({ index, page }) => {
  const { insertQuestionBefore, insertQuestionAfter } = useBuilder();

  const handleMenuClick = (position) => {
    const question = {
      label: '',
      placeholder: 'Enter your question',
      type: 'text',
    };

    if (position === 'before') {
      insertQuestionBefore(page.id, index, question);
    } else {
      insertQuestionAfter(page.id, index, question);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignSelf="baseline"
      mt="1"
      ml="1"
      mr="4"
    >
      <Menu>
        <MenuButton variant="ghost" aria-label="Options">
          <FaEllipsisH />
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<FaArrowDown />}
            onClick={() => handleMenuClick('after')}
          >
            Add Question Below
          </MenuItem>
          <MenuItem
            icon={<FaArrowUp />}
            onClick={() => handleMenuClick('before')}
          >
            Add Question Above
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default BottomMenu;
