import { Button } from '@chakra-ui/button';
import { Box, Divider, Stack } from '@chakra-ui/layout';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import BuilderProvider from './BuilderProvider';
import FormTags from './FormTags';
import FormTitle from './FormTitle';
import PageHeading from './PageHeading';
import QuestionCard from './QuestionCard';
import useBuilder from './useBuilder';

const SortableItem = SortableElement(({ value: { field, index, page } }) => {
  return (
    <li style={{ listStyle: 'none' }}>
      <QuestionCard value={field} index={index} page={page} />
    </li>
  );
});

const SortableList = SortableContainer(({ items: { questions, page } }) => {
  return (
    <ul>
      {questions.map((field, index) => (
        <SortableItem
          // This is important if array index is used, the sortable list doesn't reflect changes
          key={field?.name}
          index={index}
          value={{ field, index, page }}
        />
      ))}
    </ul>
  );
});

const SortableFormBuilder = () => {
  const { pages, addPage, orderQuestion, addQuestion } = useBuilder();

  const onSortEnd = (page, { oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    orderQuestion(page.id, oldIndex, newIndex);
  };

  return (
    <Stack direction="column" maxW="container.md">
      <FormTitle fontWeight="bold" display="flex" flex="1" mb="5" />

      <FormTags fontWeight="bold" display="flex" flex="1" mb="10" />

      <Divider my="10" />

      {pages?.map((page, index) => {
        return (
          <Box key={page.id} paddingTop={10}>
            <PageHeading page={page} index={index} />

            <SortableList
              items={{ questions: page.questions, page, index }}
              page={index}
              onSortEnd={(order) => onSortEnd(page, order)}
              useDragHandle
            />

            <Button
              minW="full"
              marginY="2"
              onClick={() => {
                addQuestion(page.id, {
                  label: 'Enter your question',
                  placeholder: '',
                  type: 'text',
                });
              }}
            >
              Add Question
            </Button>
            <Button minW="full" marginY="2" onClick={addPage}>
              Add Page
            </Button>

            <Divider my="4" />
          </Box>
        );
      })}
    </Stack>
  );
};

const Provider = React.forwardRef(BuilderProvider);

const FormBuilder = (props, ref) => {
  return (
    <Provider {...props} ref={ref}>
      <SortableFormBuilder />
    </Provider>
  );
};

export default React.forwardRef(FormBuilder);
