import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const SingleSelect = ({
  name,
  label,
  defaultValue,
  options,
  direction,
  rules,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const fieldError = errors[name];

  return (
    <FormControl isInvalid={fieldError} isRequired={rules?.required} mb="5">
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ field: { ref, ...fieldProps } }) => (
          <RadioGroup {...fieldProps}>
            <Stack spacing={2} direction={direction}>
              {options.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
      <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default SingleSelect;
