const validate = (values) => {
  const errors = {};

  if (!values.familySymptoms) {
    errors.familySymptoms = 'Required';
  }

  return errors;
};
export default validate;
