import { Container, Skeleton } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormRender from '../components/renderer';
import useAuth from '../hooks/useAuth';
import useFormManager from '../hooks/useFormManager';
import Page from '../layouts/Page';
import { canFillForms } from '../utils/permission';

const FormPreview = () => {
  const { formId } = useParams();
  const { user } = useAuth();

  const [schema, setSchema] = useState();
  const { saving, getFormSchema } = useFormManager();

  const canFill = canFillForms(user);

  const loadFormSchema = useCallback(async () => {
    try {
      const formSchema = await getFormSchema(formId);
      setSchema(formSchema);
    } catch (error) {
      console.log(error);
    }
  }, [formId, getFormSchema]);

  useEffect(() => {
    loadFormSchema();
  }, [loadFormSchema]);

  return (
    <Page
      title={canFill ? 'Complete form' : 'Form Preview'}
      subtitle={
        canFill ? 'Complete all pages of form and submit.' : 'Preview form'
      }
    >
      {saving ? (
        <Skeleton startColor="pink.500" endColor="orange.500" height="400px" />
      ) : (
        <Container minW="3xl" minH="100vh">
          <FormRender schema={schema} />
        </Container>
      )}
    </Page>
  );
};

export default FormPreview;
