import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DateInput from '../DateInput';

const DatePicker = ({
  name,
  label,
  placeholder,
  format = 'MM/DD/YYYY',
  rules,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const fieldError = errors[name];

  return (
    <FormControl isInvalid={fieldError} isRequired={rules?.required} mb="5">
      <FormLabel htmlFor={name}>{label}</FormLabel>

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Input
            {...field}
            as={DateInput}
            format={format}
            placeholder={placeholder}
          />
        )}
      />

      <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default DatePicker;
