import { useColorMode } from '@chakra-ui/color-mode';
import { Box, Flex, HStack } from '@chakra-ui/layout';
import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { generateFieldName } from '../helpers';
import useBuilder from '../useBuilder';
import BottomMenu from './BottomMenu';
import CustomTab from './CustomTab';
import DragHandle from './DragHandle';
import FieldEdit from './FieldEdit';
import FieldName from './FieldName';
import FieldOptions from './FieldOptions';
import RemoveCard from './RemoveCard';

function QuestionCard({ value, index, page }) {
  const { colorMode } = useColorMode();
  const { pages, updateQuestion } = useBuilder();
  const [cardState, setCardState] = useState(value);

  const pushUpdate = () => {
    // Ignore update if there is no change.
    if (isEqual(value, cardState)) return;

    let name = cardState?.name;

    if (!name?.length || value?.name !== name) {
      name = generateFieldName(cardState?.name, pages);
      cardState.name = name;
    }

    // Push update to the context.
    updateQuestion(page.id, index, cardState);
  };

  useEffect(() => {
    // Debounce global context.
    const timeout = setTimeout(() => {
      pushUpdate();
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardState]);

  return (
    <>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        marginBottom="4"
        padding="4"
        overflow="visible"
        bgColor={colorMode === 'light' ? 'white' : 'gray.900'}
        overflowY="visible"
      >
        <Tabs variant="unstyled">
          <HStack flex justifyContent="space-between">
            <TabList>
              <CustomTab>Edit</CustomTab>
              <CustomTab>Options</CustomTab>
            </TabList>

            <FieldName
              index={index}
              cardState={cardState}
              setCardState={setCardState}
            />
          </HStack>

          <TabPanels>
            <TabPanel px="0">
              <FieldEdit cardState={cardState} setCardState={setCardState} />
            </TabPanel>
            <TabPanel px="0">
              <FieldOptions cardState={cardState} setCardState={setCardState} />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Flex direction="row">
          <Box>
            <BottomMenu value={value} index={index} page={page} />
          </Box>
          <Box flex="2">
            <DragHandle />
          </Box>
          <Box>
            <RemoveCard value={value} index={index} page={page} />
          </Box>
        </Flex>
      </Box>
    </>
  );
}
export default QuestionCard;
