import { Editable, EditableInput, EditablePreview } from '@chakra-ui/editable';
import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import EditableControls from '../EditableControls';
import useBuilder from './useBuilder';

const FormTitle = (props) => {
  const { title, setTitle } = useBuilder();
  const [titleValue, setTitleValue] = useState('');
  useEffect(() => {
    setTitleValue(title);
  }, [title]);

  const handleSubmit = useCallback(
    (value) => {
      setTitle(value);
    },
    [setTitle]
  );

  return (
    <Stack alignItems="center" spacing="4" direction={['column', 'row']} mb="4">
      <Text fontWeight="bold">
        Form Title: &nbsp;
        <span
          role="presentation"
          aria-hidden="true"
          className="chakra-form__required-indicator"
          style={{ color: 'red' }}
        >
          *
        </span>
      </Text>
      <Editable {...props} value={titleValue} onSubmit={handleSubmit}>
        <HStack>
          <EditablePreview />
          <EditableInput onChange={(e) => setTitleValue(e.target.value)} />

          <EditableControls ml="4" />
        </HStack>
      </Editable>
    </Stack>
  );
};

export default FormTitle;
