import { get } from 'lodash';
import DatePicker from './DatePicker';
import Email from './Email';
import LongAnswer from './LongAnswer';
import MultiSelect from './MultiSelect';
import Phone from './Phone';
import ShortAnswer from './ShortAnswer';
import SingleSelect from './SingleSelect';

export const FieldTypes = {
  date: { component: DatePicker, label: 'Date' },
  email: { component: Email, label: 'Email' },
  textArea: { component: LongAnswer, label: 'Long Answer' },
  checkbox: { component: MultiSelect, label: 'Multi Select' },
  tel: { component: Phone, label: 'Phone' },
  text: { component: ShortAnswer, label: 'Short Answer' },
  radio: { component: SingleSelect, label: 'Single Select' },
};

const Field = ({ comp: Component, ...rest }) => {
  if (!Component) return null;
  return <Component {...rest} />;
};

const FieldPicker = ({ field: { type, ...rest } }) => {
  const component = get(FieldTypes, `${type}.component`, null);
  if (!type || !component) return null;
  return <Field comp={component} {...rest} />;
};

export default FieldPicker;
