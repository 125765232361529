import { Editable, EditableInput, EditablePreview } from '@chakra-ui/editable';
import { Box, Flex } from '@chakra-ui/layout';
import React, { useState, useEffect, useCallback } from 'react';
import EditableControls from '../EditableControls';
import PageMenu from './QuestionCard/PageMenu';
import useBuilder from './useBuilder';

const delay = 700;

const PageHeading = ({ page, index }) => {
  const { setProperties } = useBuilder();

  const { id, title = '', description = '' } = page;

  const [editableState, setEditableState] = useState({
    title,
    description,
  });

  const handleChange = useCallback((name, value) => {
    setEditableState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  // useEffect debounce editable state change and set properties
  useEffect(() => {
    const timeout = setTimeout(() => {
      setProperties(id, {
        title: editableState.title,
        description: editableState.description,
      });
    }, delay);

    return () => clearTimeout(timeout);
  }, [setProperties, id, editableState]);

  return (
    <Flex justifyItems="center" mb="10">
      <Box flex="1">
        <Editable
          value={editableState.title}
          size="lg"
          justifyContent="space-between"
          display="flex"
          mb="4"
          onChange={(editableValue) => handleChange('title', editableValue)}
        >
          <EditablePreview fontSize="xl" fontWeight="bold" />
          <EditableInput />
          <EditableControls />
        </Editable>

        <Editable
          value={editableState.description}
          justifyContent="space-between"
          display="flex"
          onChange={(editableValue) =>
            handleChange('description', editableValue)
          }
        >
          <EditablePreview />
          <EditableInput />
          <EditableControls />
        </Editable>
      </Box>

      <PageMenu index={index} page={page} />
    </Flex>
  );
};

export default React.memo(PageHeading);
