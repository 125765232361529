import { Container } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Login = () => {
  const { startSignIn } = useAuth();

  const location = useLocation();

  useEffect(() => {
    startSignIn();
    const { from } = location.state || { from: { pathname: '/' } };

    // object to base64
    const redirectHash = Buffer.from(
      JSON.stringify({
        ret: `//${window.location.host}/auth?${
          from?.length ? `ret=${from}` : ''
        }`,
        rts: {},
      })
    ).toString('base64');

    // Redirect to auth page
    window.location.href = `${process.env.REACT_APP_WS_AUTH_URL}/#${redirectHash}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container>Authenticating....</Container>;
};

export default Login;
