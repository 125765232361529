import {
  Avatar,
  Box,
  Button,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  LinkBox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import {
  FiChevronDown,
  FiHome,
  FiList,
  FiMenu,
  FiSettings,
} from 'react-icons/fi';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import wsLogo from '../assets/images/wonderschool/logo-base.png';
import useAuth from '../hooks/useAuth';
import { canManageForms } from '../utils/permission';

const LinkItems = [
  { name: 'Home', icon: FiHome, path: '/', allowed: [] },
  { name: 'Forms', icon: FiList, path: '/forms' },
  { name: 'Settings', icon: FiSettings, path: '/settings' },
];

export default function SidebarWithHeader({ children }) {
  const { user } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const boxBg = useColorModeValue('gray.100', 'gray.900');

  const canViewFormManager = canManageForms(user);

  return (
    <Box minH="100vh" bg={boxBg}>
      {canViewFormManager && (
        <>
          <SidebarContent
            onClose={() => onClose}
            display={{ base: 'none', md: 'block' }}
          />
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
          >
            <DrawerContent>
              <SidebarContent onClose={onClose} />
            </DrawerContent>
          </Drawer>
        </>
      )}
      {/* mobile-nav */}
      {canViewFormManager && <MobileNav onOpen={onOpen} />}
      <Box
        ml={{
          base: 0,
          md: canViewFormManager ? 60 : 0,
        }}
        p="4"
      >
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <LinkBox as={ReactRouterNavLink} to="/" width="40">
          <Image src={wsLogo} objectFit="contain" alt="Wonderschool logo" />
        </LinkBox>

        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          path={link.path}
          onClick={onClose}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, path, ...rest }) => {
  return (
    <Link as={ReactRouterNavLink} to={path} style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { auth, signOut } = useAuth();

  const menuBg = useColorModeValue('white', 'gray.900');
  const menuBorderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <LinkBox
        as={ReactRouterNavLink}
        to="/"
        width="40"
        display={{ base: 'flex', md: 'none' }}
      >
        <Image src={wsLogo} objectFit="scale-down" alt="Wonderschool logo" />
      </LinkBox>

      <HStack spacing={{ base: '0', md: '6' }}>
        {auth?.currentUser ? (
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
              >
                <HStack>
                  <Avatar
                    size={'sm'}
                    name={auth?.currentUser?.displayName}
                    src={auth?.currentUser?.photoURL}
                  />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{auth?.currentUser?.displayName}</Text>
                    <Text fontSize="xs" color="gray.600">
                      {auth?.currentUser?.email}
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList bg={menuBg} borderColor={menuBorderColor}>
                <MenuItem as={ReactRouterNavLink} to="/profile">
                  Profile
                </MenuItem>
                <MenuItem as={ReactRouterNavLink} to="/settings">
                  Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={signOut}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Button
            bgColor="blue.400"
            color="white"
            variant="link"
            as={ReactRouterNavLink}
            to="/login"
          >
            Sign in
          </Button>
        )}
      </HStack>
    </Flex>
  );
};
