import { Button, IconButton } from '@chakra-ui/button';
import { HStack } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import { Tag } from '@chakra-ui/tag';
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from '@firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FiCopy, FiEdit, FiEye, FiPlus } from 'react-icons/fi';
import { useNavigate, Navigate } from 'react-router-dom';
import { firestore } from '../api/firebase';
import DataTable from '../components/DataTable';
import useAuth from '../hooks/useAuth';
import useFormManager from '../hooks/useFormManager';
import Page from '../layouts/Page';
import { firebaseServerTimeToDate } from '../utils/dates';
import { canManageForms } from '../utils/permission';

const FormsList = () => {
  const navigate = useNavigate();
  const tableRef = React.useRef();
  const { auth, user } = useAuth();
  const [data, setData] = useState([]);
  const { duplicateForm } = useFormManager();

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    const q = query(
      collection(firestore, `forms`),
      orderBy('updatedAt', 'desc'),
      limit(25) // Limiting it to 20 to save real estate
    );

    // Application collection listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        setData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  const canManage = canManageForms(user);

  if (!canManage) return <Navigate to="/401" />;

  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({
        row: {
          original: { id, title },
        },
      }) => (
        <Button
          variant="link"
          onClick={() => {
            navigate(`/forms/${id}`);
          }}
        >
          {title}
        </Button>
      ),
    },
    {
      Header: 'Modified',
      accessor: 'updatedAt',
      Cell: ({ row }) => {
        return firebaseServerTimeToDate(
          row.original.updatedAt,
          'MM/DD/YYYY - h:mm a z'
        );
      },
    },

    {
      Header: 'Responses',
      accessor: 'responseCount',
      Cell: ({ value }) => {
        // TODO: Add a link to the responses list page
        // return <Button variant="link">{value}</Button>;
        return value ?? 0;
      },
    },

    {
      Header: 'Status',
      accessor: 'published',
      Cell: ({
        row: {
          original: { published },
        },
      }) => {
        return (
          <Tag variant="solid" colorScheme={published ? 'green' : 'orange'}>
            {!published ? 'Draft' : 'Published'}
          </Tag>
        );
      },
    },

    {
      id: 'actions',
      isNumeric: true, // Set isNumeric to true to set the column right aligned
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <>
          <Tooltip label="Preview">
            <IconButton
              variant="ghost"
              icon={<FiEye />}
              onClick={() => navigate(`/forms/${id}`)}
            />
          </Tooltip>

          <Tooltip label="Edit">
            <IconButton
              variant="ghost"
              icon={<FiEdit />}
              onClick={() => navigate(`/forms/${id}/editor`)}
            />
          </Tooltip>

          <Tooltip label="Duplicate">
            <IconButton
              variant="ghost"
              icon={<FiCopy />}
              onClick={() => {
                duplicateForm(id);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Page title="Form List" subtitle="List of forms created">
      <HStack justifyContent="flex-end">
        <Button
          bg={'blue.400'}
          rounded={'full'}
          color={'white'}
          alignSelf="flex-end"
          leftIcon={<FiPlus />}
          _hover={{ bg: 'blue.500' }}
          _focus={{ bg: 'blue.500' }}
          mb={8}
          onClick={() => {
            const newForm = doc(collection(firestore, 'forms')).id;
            navigate(`/forms/${newForm}/editor`);
          }}
        >
          Create
        </Button>
      </HStack>
      <DataTable ref={tableRef} columns={columns} data={data} />

      {/* TODO: Add pagination  */}
    </Page>
  );
};

export default FormsList;
