import { nanoid } from 'nanoid';
import React, { createContext, useReducer } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import {
  generateFieldName,
  insertQuestionAfter,
  insertQuestionBefore,
} from './helpers';

export const BuilderContext = createContext();

const initialState = {
  title: '',
  tags: [],
  id: nanoid(25),
  pages: [
    {
      id: nanoid(25),
      title: '',
      description: '',
      integration: {},
      questions: [
        {
          name: 'fieldName',
          label: 'Enter your question',
          placeholder: '',
          type: 'text',
        },
      ],
    },
  ],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TITLE':
      return {
        ...state,
        title: action.payload,
      };

    case 'SET_TAGS':
      return {
        ...state,
        tags: action.payload,
      };

    case 'SET_SCHEMA':
      return {
        ...state,
        ...action.payload,
      };

    case 'ADD_PAGE':
      return {
        ...state,
        pages: [
          ...state.pages,
          {
            id: nanoid(25),
            title: `New page ${state.pages?.length + 1}`,
            description: 'Page description',
            questions: [
              {
                name: generateFieldName('fieldName', state.pages),
                label: 'Enter your question',
                placeholder: '',
                type: 'text',
              },
            ],
          },
        ],
      };

    case 'REMOVE_PAGE':
      return {
        ...state,
        pages: state.pages.filter((page) => page.id !== action.payload),
      };

    case 'SET_PAGE_PROPERTY':
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id === action.page) {
            return {
              ...page,
              ...action.payload,
            };
          }
          return page;
        }),
      };

    case 'ADD_QUESTION':
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id === action.page) {
            return {
              ...page,
              questions: [
                ...page.questions,
                {
                  ...action.payload,
                  name: generateFieldName('fieldName', state.pages),
                },
              ],
            };
          }
          return page;
        }),
      };

    case 'REMOVE_QUESTION':
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id === action.page) {
            return {
              ...page,
              questions: page.questions.filter(
                (question) => question.name !== action.payload
              ),
            };
          }
          return page;
        }),
      };

    case 'UPDATE_QUESTION':
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id === action.page) {
            const currentQuestions = page.questions;

            currentQuestions[action.index] = {
              ...currentQuestions[action.index],
              ...action.payload,
            };

            return {
              ...page,
              questions: currentQuestions,
            };
          }
          return page;
        }),
      };

    case 'MOVE_QUESTION':
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id === action.page) {
            return {
              ...page,
              questions: arrayMove(
                page.questions,
                action.payload.oldIndex,
                action.payload.newIndex
              ),
            };
          }
          return page;
        }),
      };

    case 'INSERT_QUESTION_BEFORE':
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id === action.page) {
            return {
              ...page,
              questions: insertQuestionBefore(page.questions, action.index, {
                ...action.payload,
                name: generateFieldName('fieldName', state.pages),
              }),
            };
          }
          return page;
        }),
      };

    case 'INSERT_QUESTION_AFTER':
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id === action.page) {
            return {
              ...page,
              questions: insertQuestionAfter(page.questions, action.index, {
                ...action.payload,
                name: generateFieldName('fieldName', state.pages),
              }),
            };
          }
          return page;
        }),
      };

    default:
      return state;
  }
};

const BuilderProvider = ({ children, schema, onPublish }, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    dispatch({
      type: 'SET_SCHEMA',
      payload: schema,
    });
  }, [schema]);

  React.useImperativeHandle(ref, () => ({
    publishForm() {
      if (typeof onPublish === 'function') onPublish(state);
    },
  }));
  return (
    <BuilderContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

export default BuilderProvider;
