import { Input } from '@chakra-ui/input';
import { Box, Stack } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import React from 'react';
import { FieldTypes } from '../../fields';
import FieldSelectable from './FieldSelectable';

// Field types list.
const fieldTypes = Object.keys(FieldTypes).map((key) => ({
  label: FieldTypes[key].label,
  value: key,
}));

const FieldEdit = ({ cardState, setCardState }) => {
  return (
    <Box>
      <Stack direction="row">
        <Input
          placeholder="Enter your question"
          value={cardState?.label}
          width="70%"
          onFocus={(e) => e.target.select()}
          onChange={(e) =>
            setCardState((prev) => ({ ...prev, label: e.target.value }))
          }
        />
        <Select
          width="30%"
          value={cardState?.type}
          onChange={(e) =>
            setCardState((prev) => ({ ...prev, type: e.target.value }))
          }
        >
          {fieldTypes.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Stack>

      {/* Only visible if type is checkbox or radio */}
      {(cardState?.type === 'radio' || cardState?.type === 'checkbox') && (
        <FieldSelectable cardState={cardState} setCardState={setCardState} />
      )}
    </Box>
  );
};

export default React.memo(FieldEdit);
