import { IconButton } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import useBuilder from '../useBuilder';

const RemoveCard = ({ value, page, index }) => {
  const { removeQuestion } = useBuilder();

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        disabled={!index && page?.questions.length === 1}
        size="xs"
        mr="1"
        ml="4"
        icon={<FaTrash />}
        onClick={() => removeQuestion(page.id, value.name)}
      />
    </Box>
  );
};

export default RemoveCard;
